<template>
  <div class="m2 wrapper wrapper--default">
    <Loading v-if="loading" />
    <div v-else>
      <h3 class="text-center">Verkoop order - {{ id }}</h3>
      <hr />
      <div class="flex _row spaceevenly">
        <div class="flexgrow m2">
          <TextBox
            :label="`Ordernummer`"
            :value="`${sales_order_header.Ordernummer}`"
          />
          <router-link
            :to="{
              name: 'klantpagina',
              params: { id: sales_order_header.Klant },
            }"
            ><TextBox label="Klant" :value="`${sales_order_header.Klant}`"
          /></router-link>
          <TextBox
            label="Klant naam"
            :value="`${sales_order_header.klantnaam}`"
          />
          <TextBox
            label="Klant adres"
            :value="`${sales_order_header.klantadres}`"
          />
          <TextBox
            label="Klant stad"
            :value="`${sales_order_header.klantstad}`"
          />
          <TextBox
            label="Klant land"
            :value="`${sales_order_header.klantland}`"
          />
          <TextBox
            label="Naam koper"
            :value="`${sales_order_header.Naamkoper}`"
          />
          <TextBox
            label="BTW nummer"
            :value="`${sales_order_header.Btwnummer}`"
          />
          <TextBox
            label="Incoterms"
            :value="`${sales_order_header.Incoterms} - ${sales_order_header.Plaats}`"
          />
          <TextBox
            label="Export land"
            :value="`${sales_order_header.Exportland}`"
          />
          <TextBox
            label="Grens EG"
            :value="`${sales_order_header.Grensovergang}`"
          />
          <TextBox
            label="Exportdocument"
            :value="`${sales_order_header.Exportdocument}`"
          />
        </div>
        <div class="flexgrow m2">
          <TextBox label="Valuta" :value="`${sales_order_header.Valuta}`" />
          <TextBox
            label="Verkoper"
            :value="`${sales_order_header.Verkopernaam}`"
          />
          <TextBox
            label="Orderdatum"
            :value="`${sales_order_header.OrderDatum}`"
          />
          <TextBox
            label="Datum levering"
            :value="`${sales_order_header.LeveringDatum}`"
          />
          <TextBox
            label="Datum betaling"
            :value="`${sales_order_header.BetaalDatum}`"
          />
          <TextBox
            label="Aanbetaling"
            :value="`${sales_order_header.Aanbetaling}`"
          />
          <TextBox
            label="Datum aanbetaling"
            :value="`${sales_order_header.DatumAanbetaling}`"
          />
          <TextBox label="Garantie" :value="`${sales_order_header.Garantie}`" />
          <TextBox label="BTW borg" :value="`${sales_order_header.BTWborg}`" />
        </div>
      </div>
      <div class="p2">
        <h4 class="text-center">Regels</h4>
        <table :class="`w100 table__border--${sales_order_header.Bu}`">
          <thead :class="`table__head--${sales_order_header.Bu}`">
            <th>Rg</th>
            <th>Rt</th>
            <th>Status</th>
            <th>Itemno</th>
            <th>Omschrijving</th>
            <th>Chassisnummer</th>
            <th>Factuurprijs</th>
            <th>Nettoprijs</th>
            <th>Factuurdatum</th>
            <th>Afleverdatum</th>
          </thead>

          <tbody>
            <tr
              v-for="(item, key) in sales_order_rules"
              :key="key"
              :class="`table__row--color`"
            >
              <td>{{ item.Regelnummer }}</td>
              <td>{{ item.Regeltype }}</td>
              <td>{{ item.Statusnaam }}</td>
              <td>
                <ImageHover :itemnummer="item.Itemnummer" :bu="item.bu" />
              </td>
              <td>{{ item.omschrijving }}</td>
              <td>{{ item.chassisno }}</td>
              <td class="table__data--right">€ {{ item.factuurprijs }}</td>
              <td class="table__data--right">€ {{ item.nettoprijs }}</td>
              <td class="table__data--right" v-if="item.Factuurdatum">
                {{ item.Factuurdatum }}
              </td>
              <td v-else></td>
              <td class="table__data--right" v-if="item.Afleverdatum">
                {{ item.Afleverdatum }}
              </td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import TextBox from "@/components/TextBox.vue";
import ImageHover from "@/components/ImageHover.vue";
import Loading from "@/components/Loading.vue";

export default {
  props: ["id"],
  components: { TextBox, ImageHover, Loading },
  data: () => ({
    sales_order_header: null,
    sales_order_rules: null,
    loading: true,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request(`sales-order-details/${this.id}`, "GET").then((values) => {
        console.log(values);
        this.sales_order_header = values.sales_order_header;
        this.sales_order_rules = values.sales_order_rules;
        this.loading = false;
      });
    },
  },
};
</script>
